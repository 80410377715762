import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Group,
  Modal,
  CopyButton,
} from "@mantine/core";

// icons
import { IoWallet } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import { useWeb3React } from "@web3-react/core";
import useAuth from "hooks/useAuth";
import useGlobalState from "hooks/useGlobalState";
import { Skeleton } from "components/Skeleton";

const WalletInfoModal = () => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const { account } = useWeb3React()
  const { logout } = useAuth();
  const { userProps } = useGlobalState()

  const handleInfoModalOpen = () => {
    setIsInfoModalOpen(true);
  };
  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const handleDisconnect = () => {
    logout()
    setIsInfoModalOpen(false)
  };

  const handleCopyAddress = () => {
    // handle copy address logic here
  };

  const shortenAddress = (address: string) => {
    const prefix = address.substring(0, 6);
    const suffix = address.substring(address.length - 4);
    return `${prefix}...${suffix}`;
  };

  return (
    <>
      <button
        onClick={handleInfoModalOpen}
        className="text-4xl p-3 rounded-full hover:bg-gray-100 -mt-2 group"
      >
        <IoWallet className="group-hover:text-cusOrange transition-all duration-200" />
      </button>

      <Modal
        opened={isInfoModalOpen}
        onClose={handleInfoModalClose}
        centered
        withCloseButton
        title="Wallet Info"
        classNames={{
          title: "text-cusBlack font-bold text-lg xsm:text-xl mxl:text-2xl",
          content:
            "rounded-lg xsm:rounded-xl pb-2 sm:pb-3 px-1 sm:px-2 bg-white w-full xsm:max-w-sm sm:max-w-md",
          close:
            "hover:bg-gray-200 w-8 md:w-9 xl:w-10 h-8 md:h-9 xl:h-10 rounded-full [&>svg]:h-5 lg:[&>svg]:h-6 mxl:[&>svg]:h-7 [&>svg]:w-auto [&>svg]:text-cusBlack/70",
        }}
      >
        <Group spacing="xs">
          <Group className="w-full flex justify-between items-center">
            <Group>
              <Avatar
                radius="xl"
                src="/assets/images/avatar.png"
                alt="Avatar"
                size={52}
              />
              <h3 className="text-base sm:text-lg mxl:text-xl text-cusBlack font-bold">
                {shortenAddress(account)}
              </h3>
            </Group>

            <div>
              {account ? (
                <Badge color="green" variant="filled" size="lg">
                  Connected
                </Badge>
              ) : (
                <Badge color="gray" variant="filled" size="lg">
                  Not connected
                </Badge>
              )}
            </div>
          </Group>
        </Group>

        <Divider className="my-4 border-cusBlack/20" />

        {/* <Group spacing={0}>
          <img src={network.logo} alt={network.name} className="w-6 h-auto" />
          <h2 className="text-base text-cusBlack font-medium ml-1">
            {network.name}
          </h2>
        </Group> */}

        <div className="flex justify-between items-center text-cusBlack mb-2 ml-5 mr-3">
          <h3>ETH Balance:</h3>
          {
            userProps ? (
              <h3 className="font-bold">{userProps.ethBalance.toLocaleString('en-US', { maximumFractionDigits: 3 })} ETH</h3>
            ) : (
              <Skeleton height={24} width={80} />
            )
          }
        </div>
        <div className="flex justify-between items-center text-cusBlack ml-5 mr-3">
          <h3>NFT Balance:</h3>
          {
            userProps ? (
              <h3 className="font-bold">{userProps.nftBalance} NFT(s)</h3>
            ) : (
              <Skeleton height={24} width={80} />
            )
          }
        </div>

        <Divider className="my-4 border-cusBlack/20" />

        {/* <h2 className="text-cusBlack text-sm ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </h2> */}

        <div className="mt-5 w-full flex flex-col xsm:flex-row space-y-3 xsm:space-y-0 xsm:space-x-3">
          <CopyButton value={account}>
            {({ copied, copy }) => (
              <Button
                variant="outline"
                className={`rounded-full w-full  ${copied ? "text-green-500" : ""
                  }`}
                onClick={() => {
                  handleCopyAddress();
                  copy();
                }}
                leftIcon={<MdOutlineContentCopy />}
              >
                {copied ? "Copied address" : "Copy Address"}
              </Button>
            )}
          </CopyButton>

          <Button
            variant="white"
            className="rounded-full w-full  bg-red-500 text-white"
            onClick={() => {
              handleDisconnect();
              handleInfoModalClose();
            }}
          >
            Disconnect
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default WalletInfoModal;
