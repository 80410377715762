import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ethers } from "ethers";
import { ChainId, ConnectorNames, RPC_URLS } from "config";

const POLLING_INTERVAL = 12000;
export const supportedChainIds = Object.keys(ChainId).filter(x => parseInt(x) > 0).map(x => Number(x));

const injected = new InjectedConnector({ supportedChainIds });

const walletconnect = new WalletConnectConnector({
    supportedChainIds,
    rpc: RPC_URLS,
    qrcode: true,
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect
};

export const getLibrary = (provider): ethers.providers.Web3Provider => {
    const library = new ethers.providers.Web3Provider(provider);
    library.pollingInterval = POLLING_INTERVAL;
    return library;
};

