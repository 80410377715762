import "./App.css";
import { MantineProvider } from "@mantine/core";
import AllRoutes from "./Routes";
import { useLocation } from "react-router-dom";
import { useEagerConnect } from "hooks/useEagerConnect";

function App() {
  const { pathname } = useLocation();
  useEagerConnect()

  return (
    <MantineProvider
      // withGlobalStyles
      withNormalizeCSS
      theme={{
        loader: "dots",
        breakpoints: {
          xs: "0",
          xxsm: "320",
          xsm: "440",
          sm: "640",
          md: "768",
          lg: "1024",
          xl: "1280",
          mxl: "1380",
          "2xl": "1536",
          "3xl": "1750",
        },
      }}
    >
      <div className="flex flex-col justify-between items-stretch w-full">
        <div className="flex-1 w-full">
          <AllRoutes />
        </div>
      </div>
    </MantineProvider>
  );
}

export default App;
