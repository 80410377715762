import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import cactusAbi from 'config/abi/cactus.json'
import { NftProps, UserProps } from 'config/types'
import useRefresh from 'hooks/useRefresh'
import multicall from 'utils/multicall'
import { simpleRpcProvider } from 'utils/providers'
import { getCactusAddress } from 'utils/addressHelpers'
import { getCactusContract } from 'utils/contractHelpers'
import { getBalanceNumber } from 'utils/formatBalance'

interface ContextProps {
    nftProps: NftProps
    userProps: UserProps
    updateState: () => void
}

export const StateContext = createContext<ContextProps>({
    nftProps: undefined,
    userProps: undefined,
    updateState: undefined
})

export const StateContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [nftProps, setNftProps] = useState<NftProps>()
    const [userProps, setUserProps] = useState<UserProps>()
    const [syncFlag, setSyncFlag] = useState(true)
    const { fastRefresh } = useRefresh()
    const { chainId, account } = useWeb3React()

    const onUpdate = useCallback(() => {
        setSyncFlag(!syncFlag)
    }, [])

    useEffect(() => {
        const fetchContractData = async () => {
            const cactusAddress = getCactusAddress(chainId)

            const calls = [
                { address: cactusAddress, name: 'mintPrice' },
                { address: cactusAddress, name: 'maxSupply' },
                { address: cactusAddress, name: 'totalSupply' },
                { address: cactusAddress, name: 'maxPerWallet' },
            ]
            const [[mintPrice], [maxSupply], [totalSupply], [maxPerWallet]]
                = await multicall(cactusAbi, calls, chainId)

            setNftProps({
                maxPerWallet: Number(maxPerWallet._hex),
                maxSupply: Number(maxSupply._hex),
                price: getBalanceNumber(new BigNumber(mintPrice._hex)),
                totalSupply: Number(totalSupply._hex)
            })

            if (account) {
                const ethBalance = await simpleRpcProvider(chainId).getBalance(account)
                const userCalls = [
                    { address: cactusAddress, name: 'mintedPerWallet', params: [account] },
                    { address: cactusAddress, name: 'balanceOf', params: [account] },
                ]
                const [[nftMinted], [nftBalance]] = await multicall(cactusAbi, userCalls, chainId)
                setUserProps({
                    ethBalance: getBalanceNumber(new BigNumber(ethBalance.toString())),
                    nftBalance: Number(nftBalance._hex),
                    nftMinted: Number(nftMinted._hex)
                })
            } else {
                setUserProps(undefined)
            }
        }
        fetchContractData()
    }, [fastRefresh, syncFlag, chainId, account])

    return (
        <StateContext.Provider value={{ nftProps, userProps, updateState: onUpdate }}>
            {children}
        </StateContext.Provider>
    )
}
