import { useState, useRef, useMemo } from "react";
import {
  NumberInput,
  Group,
  ActionIcon,
  NumberInputHandlers,
  Divider,
  Container,
} from "@mantine/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { toast } from 'react-hot-toast';
import { GoPlus } from "react-icons/go";
import { HiMinus } from "react-icons/hi";
import trainerLatestNbg from "assets/images/trainerLatestNbg.png";
import defaultImg from "assets/images/mint/default.jpg";
import RanAnimatedBtn from "components/RanAnimatedBtn/RanAnimatedBtn";
import { Skeleton } from "components/Skeleton";
import { useCactus } from "hooks/useContract";
import useGlobalState from "hooks/useGlobalState";
import { getDecimalAmount } from "utils/formatBalance";
import WalletConnectModal from "./WalletConnectModal";
import WalletInfoModal from "./WalletInfoModal";

const Mint = () => {
  const [value, setValue] = useState<number>(1)
  const [pendingTx, setPendingTx] = useState(false)
  const handlers = useRef<NumberInputHandlers>()
  const { account } = useWeb3React()

  const cactusContract = useCactus()

  const { nftProps, updateState, userProps } = useGlobalState()

  const maxAmount = useMemo(() => {
    if (!nftProps && !userProps) {
      return 5;
    }
    if (!userProps) {
      return Math.min(nftProps.maxPerWallet, nftProps.maxSupply - nftProps.totalSupply)
    }
    return Math.min(nftProps.maxPerWallet - userProps.nftMinted, nftProps.maxSupply - nftProps.totalSupply)
  }, [nftProps, userProps])

  const handleMint = async () => {
    if (value <= 0) toast.error("Invalid mint amount")
    if (value > maxAmount) toast.error("Exceed mint limit")
    if (value * nftProps.price > userProps.ethBalance) toast.error("Insufficient ETH in your wallet")
    setPendingTx(true)
    try {
      await cactusContract.mintNft(value, { value: getDecimalAmount(new BigNumber(value * nftProps.price)).toJSON() })
      toast.success(`${value} NFT(s) minted to your wallet`)
      updateState()
    } catch (err) {
      console.error(err)
    } finally {
      setPendingTx(false)
    }
  }

  return (
    <div
      className="h-screen w-full relative pt-36 xl:pt-44 pb-44 px-2 xxsm:px-4 z-[1]"
      style={{
        background: `url(${trainerLatestNbg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Container
        size="100%"
        className="w-full bg-white p-4 xsm:p-6 sm:p-10 rounded-2xl max-w-3xl mxl:max-w-[900px]"
      >
        <div
          className={`flex ${account ? "justify-between" : "justify-center"
            } items-center`}
        >
          <h3 className="text-cusBlack uppercase font-semibold text-center text-[18px] xsm:text-[20px] sm:text-[28px] md:text-[32px] lg:text-[36px] xl:text-[40px] mxl:text-[48px] font-cusWickedMouse tracking-wider sm:-mt-3">
            Cacti art by ut
          </h3>
          {account && (
            <WalletInfoModal />
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between items-start mt-3 sm:mt-5 mxl:mt-7">
          <div className="w-full md:w-72 mxl:w-80 h-72 xsm:h-96 md:h-72 mxl:h-80 bg-[#7EC3D9] rounded-lg flex justify-center items-center">
            <img
              src={defaultImg}
              alt=""
              className="h-[100%] w-auto mx-auto"
            />
          </div>

          <div className="flex-1 mt-8 md:mt-0 md:ml-8 xl:ml-14 mxl:ml-16 w-full">
            <div className="flex justify-between items-center text-cusBlack text-sm xsm:text-lg sm:text-xl mxl:text-2xl">
              <h3>Mint is</h3>
              <h3 className="text-[#FA922A] font-bold">Live</h3>
            </div>

            <Divider className="border-[#FA922A] my-2" />

            <div className="flex justify-between items-center text-cusBlack text-xs xsm:text-base sm:text-lg mxl:text-[22px]">
              <h3>Price:</h3>
              {
                nftProps ? (
                  <h3 className="font-bold">{nftProps.price} ETH</h3>
                ) : (
                  <Skeleton height={24} width={80} />
                )
              }
            </div>

            <Divider className="border-[#FA922A] my-2 xsm:my-3" />

            <div className="flex justify-between items-center text-cusBlack text-xs xsm:text-base sm:text-lg mxl:text-[22px]">
              <h3>Total Minted:</h3>
              {
                nftProps ? (
                  <h3 className="font-bold">{nftProps.totalSupply.toLocaleString('en-US')} / {nftProps.maxSupply.toLocaleString('en-US')}</h3>
                ) : (
                  <Skeleton height={24} width={80} />
                )
              }
            </div>

            <Divider className="border-[#FA922A] my-2 xsm:my-3" />

            <div className="flex justify-between items-center text-cusBlack text-xs xsm:text-base sm:text-lg mxl:text-[22px]">
              <h3>Your Minted:</h3>
              {
                userProps ? (
                  <h3 className="font-bold">{userProps.nftMinted} NFT(s)</h3>
                ) : (
                  <Skeleton height={24} width={80} />
                )
              }
            </div>

            <Divider className="border-[#FA922A] my-2 xsm:my-3" />

            <div className="flex justify-between items-center text-cusBlack text-xs xsm:text-base sm:text-lg mxl:text-[22px]">
              <h3>Quantity:</h3>
              <Group
                spacing={5}
                className="bg-[#F9D67B] border border-[#26316D] rounded-md"
              >
                <ActionIcon
                  onClick={() => handlers?.current?.decrement()}
                  size={window?.innerWidth < 440 ? 26 : 40}
                  variant="light"
                >
                  <HiMinus className="text-cusBlack" />
                </ActionIcon>

                <NumberInput
                  type="number"
                  hideControls
                  value={value}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  handlersRef={handlers}
                  onChange={(val: number) => setValue(val)}
                  max={maxAmount}
                  min={1}
                  step={1}
                  classNames={{
                    input:
                      "w-12 xsm:w-16 text-2xl lg:text-3xl text-center outline-none text-white bg-transparent focus:border-none",
                    root: "border-none",
                  }}
                  variant="filled"
                />

                <ActionIcon
                  onClick={() => handlers?.current?.increment()}
                  size={window?.innerWidth < 440 ? 26 : 40}
                  variant="light"
                >
                  <GoPlus className="text-cusBlack" />
                </ActionIcon>
              </Group>
              {
                nftProps ? (
                  <h3 className="font-bold">{(value * nftProps.price).toFixed(2)} ETH</h3>
                ) : (
                  <Skeleton height={24} width={80} />
                )
              }
            </div>

            <Divider className="border-[#FA922A] my-2 xsm:my-3" />

            <div className="mt-5">
              {!account ? (
                <WalletConnectModal />
              ) : (
                <RanAnimatedBtn
                  fullWidth
                  classNames="flex items-center justify-center bg-[#26316d] text-white py-2 lg:py-3 px-7 sm:px-9 xl:px-12 mxl:px-14 rounded-full text-sm xl:text-base font-semibold uppercase transition-all duration-300"
                  disabled={!nftProps || !userProps || pendingTx}
                  onClick={handleMint}
                >
                  MINT NOW
                </RanAnimatedBtn>
              )}
            </div>

            {
              nftProps && (
                <p className="text-[#FA922A] text-center mt-3 xsm:mt-2 text-sm xsm:text-base mxl:text-lg font-semibold">
                  ***{nftProps.maxPerWallet} MAX PER WALLET
                </p>
              )
            }

          </div>
        </div>
      </Container>
    </div>
  );
};

export default Mint;
