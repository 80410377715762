import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Mint from "./pages/Mint/Mint";

const AllRoutes = () => {
  return (
    <Routes>
      <Route exact path="*" element={<Mint />} />
    </Routes>
  );
};

export default AllRoutes;
