import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getCactusContract } from 'utils/contractHelpers'

export const useCactus = () => {
  const { chainId, library } = useActiveWeb3React()
  return useMemo(() => getCactusContract(
    chainId, library.getSigner()), [chainId, library])
}


