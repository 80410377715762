import { CHIAN_ID, ChainId } from 'config'
import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import cactusAbi from 'config/abi/cactus.json'
import multicallAbi from 'config/abi/multicall.json'
import { getCactusAddress, getMulticallAddress } from './addressHelpers'

const getContract = (abi: any, address: string, chainId: ChainId, signer?: ethers.Signer | ethers.providers.Provider) => {
    const signerOrProvider = signer ?? simpleRpcProvider(chainId)
    if (!address) return null
    return new ethers.Contract(address, abi, signerOrProvider)
}

export const getCactusContract = (chainId: ChainId, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(cactusAbi, getCactusAddress(chainId), chainId, signer)
}

export const getMulticallContract = (chainId: ChainId, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(multicallAbi, getMulticallAddress(chainId), chainId, signer)
}
