export * from './contracts'
export * from './endpoints'

export const RPC_URLS: { [key in ChainId]: string } = {
    1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    5: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    11155111: "https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    56: "https://bsc-dataseed.binance.org"
};

export const BLOCK_EXPLORER_URLS: { [key in ChainId]: string } = {
    1: 'https://etherscan.io/',
    5: 'https://goerli.etherscan.io/',
    11155111: 'https://sepolia.etherscan.io/',
    56: 'https://bscscan.com'
}

export const NATIVE_CURRENCIES: {
    [key in ChainId]: { name: string, symbol: string, decimals: number }
} = {
    1: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    5: { name: 'Goerli ETH', symbol: 'ETH', decimals: 18 },
    11155111: { name: 'Sepolia ETH', symbol: 'ETH', decimals: 18 },
    56: { name: 'BNB', symbol: 'BNB', decimals: 18 },
}

export const CHAIN_NAME: { [key in ChainId]: string } = {
    1: 'Ethereum Mainnet',
    5: 'Goerli test network',
    11155111: 'Sepolia test network',
    56: 'BNB Smart Chain'
}

export enum ConnectorNames {
    Injected = "injected",
    WalletConnect = "walletconnect"
}

export enum ChainId {
    MAINNET = 1,
    GOERLI = 5,
    SEPOLIA = 11155111,
    BSC = 56
}

export const ConnectorLocalStorageKey = "CACTUS-CONNECTOR-ID";
