import { useState } from "react";
import { Button, Modal } from "@mantine/core";

import metamask from "assets/images/metamask.png";
import walletConnect from "assets/images/walletConnect.png";
import RanAnimatedBtn from "components/RanAnimatedBtn/RanAnimatedBtn";
import { ConnectorLocalStorageKey, ConnectorNames } from "config";
import useAuth from "hooks/useAuth";

function WalletConnectModal() {
  const [isWcModalOpen, setIsWcModalOpen] = useState(false);

  const { login } = useAuth();
  const handleConnect = (connectorId: ConnectorNames) => {
    login(connectorId);
    localStorage.setItem(ConnectorLocalStorageKey, connectorId);
    setIsWcModalOpen(false);
  };

  return (
    <>
      <button
        onClick={() => setIsWcModalOpen(!isWcModalOpen)}
        className="w-full"
      >
        <RanAnimatedBtn
          fullWidth
          classNames="flex items-center justify-center bg-[#26316d] text-white py-2 lg:py-3 px-7 sm:px-9 xl:px-12 mxl:px-14 rounded-full text-sm xl:text-base font-semibold uppercase transition-all duration-300"
        >
          CONNECT WALLET
        </RanAnimatedBtn>
      </button>

      <Modal
        centered
        opened={isWcModalOpen}
        onClose={() => setIsWcModalOpen(false)}
        withCloseButton
        title="Connect Wallet"
        classNames={{
          title: "text-cusBlack font-bold text-lg xsm:text-xl mxl:text-2xl",
          content:
            "rounded-lg xsm:rounded-xl pb-2 sm:pb-3 px-1 sm:px-2 bg-white w-full xsm:max-w-sm sm:max-w-md",
          close:
            "hover:bg-gray-200 w-8 md:w-9 xl:w-10 h-8 md:h-9 xl:h-10 rounded-full [&>svg]:h-5 lg:[&>svg]:h-6 mxl:[&>svg]:h-7 [&>svg]:w-auto [&>svg]:text-cusBlack/70",
        }}
      >
        <Button
          unstyled
          onClick={() => handleConnect(ConnectorNames.Injected)}
          className="border-2 border-cusBlack/10 rounded-lg xsm:rounded-xl px-3 sm:px-4 py-2 sm:py-3 w-full hover:shadow-lg transition-all duration-300"
        >
          <div className="flex justify-start items-center">
            <div className="w-auto h-8 xsm:h-10 sm:h-12">
              <img src={metamask} alt="" className="w-auto h-full" />
            </div>
            <h3 className="text-sm xsm:text-lg mxl:text-xl font-bold text-cusBlack/90 ml-2 xsm:ml-3 flex-1 text-left">
              Connect with Metamask
            </h3>
          </div>
        </Button>

        <Button
          unstyled
          onClick={() => handleConnect(ConnectorNames.WalletConnect)}
          className="border-2 border-cusBlack/10 rounded-lg xsm:rounded-xl px-3 sm:px-4 py-2 sm:py-3 w-full hover:shadow-lg transition-all duration-300 mt-3"
        >
          <div className="flex justify-start items-center">
            <div className="w-auto h-8 xsm:h-11 sm:h-[52px]">
              <img src={walletConnect} alt="" className="w-auto h-full" />
            </div>
            <h3 className="text-sm xsm:text-lg mxl:text-xl font-bold text-cusBlack/90 ml-2 xsm:ml-3 flex-1 text-left">
              Connect with WalletConnect
            </h3>
          </div>
        </Button>
      </Modal>
    </>
  );
}

export default WalletConnectModal;
