import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from 'utils/web3React'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { StateContextProvider } from 'contexts/StateContext'

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <RefreshContextProvider>
                <StateContextProvider>
                    {children}
                </StateContextProvider>
            </RefreshContextProvider>
        </Web3ReactProvider>
    )
}

export default Providers
