import { CHIAN_ID, ChainId } from 'config'
import addresses from 'config/contracts'
import { Address } from 'config/types'

export const getAddress = (address: Address, chainId: ChainId): string => {
  return address[chainId] ? address[chainId] : address[CHIAN_ID]
}

export const getMulticallAddress = (chainId: ChainId) => {
  return getAddress(addresses.multiCall, chainId)
}

export const getCactusAddress = (chainId: ChainId) => {
  return getAddress(addresses.cactus, chainId)
}

